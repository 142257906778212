import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Signer, DeployUtil, CLPublicKey, CasperServiceByJsonRPC } from "casper-js-sdk";

function App() {

  const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
  const URL = 'http://116.202.115.195:7777/rpc';
  
  let casperService = new CasperServiceByJsonRPC(PROXY_URL+URL);
  let publicKey = "019ca5f687345d3da7829c070818728729edc6d1dc02cd30fb8242abf52812ac16";
 
  const connect  = async () => {
    casperService.getStateRootHash().catch((response) => {
      document.getElementById("textBalance").innerHTML = response;
    });
  }
  
  const disconnect = async () => {
  }
  
  useEffect(() => {  
    document.getElementById("textAddress").innerHTML = publicKey;
  }, []);
      
  return (
    <div className="App">
      <header className="App-header"> 
        <button id="btnConnect" onClick={connect}>Connect</button>
        <button id="btnDisconnect" onClick={disconnect}>Disconnect</button>
        <h1 id="textAddress">PublicKeyHex </h1>
        <h1 id="textBalance">Balance </h1>
        <h1>Transer</h1>
        <label for="Amount">Amount - min amount 25000000000</label>
        <input id="Amount" type="number" />
        <label for="Recipient">Recipient</label>
        <input id="Recipient" type="text" />
        <button id="btnSend" >Send</button>
        <h1 id="tx"></h1>
      </header>
    </div>
  );
}

export default App;
